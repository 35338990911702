import React from "react";
import Layout from "./layout";
import { addLocaleData } from "react-intl";

import messages from "data/messages/en";
import "intl/locale-data/jsonp/en";

const en = require("react-intl/locale-data/en");
addLocaleData(en);

const layout = (props) => <Layout {...props} i18nMessages={messages} />;

export default layout;
