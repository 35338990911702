import React from "react";
import PropTypes from "prop-types";
import Layout from "components/layout.en";
import SEO from "components/seo";
import Heads from "components/heads";
import Ministries from "components/ministries";
import { StaticImage } from "gatsby-plugin-image";

function IndexPage(props) {
  return (
    <Layout location={props.location}>
      <SEO keywords={[`Myanmar`, `government`, `ministry`, `NUG`]} title="Home" />

      <div className="relative">
        {/* <StaticImage src="../images/impression-en.jpg" alt="Impression" className="w-full" placeholder="blurred" /> */}
        <StaticImage
          src="../images/impression-en_update.jpg"
          alt="Impression"
          className="w-full"
          placeholder="blurred"
        />
      </div>
      <section className="flex-1 w-full px-4 py-4 mx-auto">
        <Heads lang="en" />
      </section>
      <section className="flex-1 w-full px-4 py-4 mx-auto">
        <Ministries lang="en" />
      </section>
    </Layout>
  );
}

IndexPage.propTypes = {
  location: PropTypes.any,
};

export default IndexPage;
